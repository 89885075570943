import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Layout from '../components/layout';
import SEO from '../components/SEO';
import sectionConductor from '../sections/section-conductor';

class ManifestoPage extends Component {
  render() {
    const { data, location } = this.props;
    const { contentfulLayout: page } = data;
    const renderedSections = page.sections.map((section, i) => sectionConductor(section, i));
    const pageWrapClasses = classNames({
      page: true,
      [`page--${page.contentfulid}`]: true,
    });

    return (
      <div className={pageWrapClasses}>
        <Layout navbarIsInverted={false}>
          <SEO title={page.title} metadata={page.metadata} />
          {renderedSections}
        </Layout>
      </div>
    );
  }
}

export default ManifestoPage;

export const pageQuery = graphql`
  query {
    contentfulLayout(contentfulid: { eq: "manifesto" }) {
      contentfulid
      title
      url
      metadata {
        ...Metadata
      }
      ...AllSectionsFragment
    }
  }
`;

ManifestoPage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
